<template>
  <v-layout column>
    <FieldHeader
      class="my-3"
      v-if="!hideHeader"
      :field="field"
      :patient="patient"
      :session.sync="session"
      :owner="owner"
      :hideCollapseBtn="hideCollapseBtn"
      :collapsed="hideBody"
      @history="onHistory"
      @collapse="onCollapse"
      :status="status"
      :name="name"
      autosave
    ></FieldHeader>
    <v-layout v-if="!disabled && !hideBody" column align-center>
      <SubviewCard class="mx-6" noActions :padding="false" style="width: 100%">
        <PregnancyInterviewForm
          :value.sync="data"
          class="ma-6"
        ></PregnancyInterviewForm>
      </SubviewCard>
    </v-layout>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :hideEdit="hideEdit"
      :session="session"
      :owner="owner"
      @edit="onEdit"
      @append="onAppend"
    >
      <PregnancyInterviewForm
        :value="data"
        disabled
        class="ma-6"
      ></PregnancyInterviewForm>
    </DisabledFieldWrapper>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    syncId: {
      type: String,
      default: "patientCard",
    },
    clientOnlyPredefined: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    data: {
      get() {
        if (!this.field.data) {
          return {
            number: 1,
            birth_number: 1,
            drugs: "",
            birth_date: this.patient.birth_date,
            hbd: "",
            hbd_day: "",
            mass: "",
            length: "",
            pregnancy_type: 0,
            reason: "",
            comments: "",
            apgar: "",
            apgar_comments: "",
          };
        }

        if (!this.field.data.birth_date) {
          this.$set(this.field.data, "birth_date", this.patient.birth_date);
        }

        return this.field.data;
      },
      set(value) {
        this.$set(this.field, "data", value);
      },
    },
    dataJson: {
      get() {
        return JSON.stringify(this.data);
      },
    },
  },
  components: {
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    PregnancyInterviewForm: () =>
      import("@/components/forms/patientcards/PregnancyInterviewForm"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onHistory() {
      this.$emit("history");
    },
    onPredefined(data) {
      this.$emit("predefined", data);
    },
    onCollapse() {
      this.$emit("collapse");
    },
    onAppend(data) {
      this.$emit("append", data);
    },
  },
  mounted() {
    if (!this.disabled && this.autosave) {
      this.startWatching("dataJson");
    }
  },
};
</script>